import * as React from 'react'
import Layout from "../components/layout/layout";
import {graphql} from "gatsby";
import {Trans, useTranslation} from "react-i18next";
import {StaticImage} from "gatsby-plugin-image";
import {containerFluid, inner, parkingBox} from "./parking.module.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faVideo} from "@fortawesome/free-solid-svg-icons";

const ParkingPage = () => {
    const {t} = useTranslation();
    return (
        <Layout pageTitle={t('pages.parking.title')} pageDescription={t('pages.parking.description')}
                pageKeywords={t('pages.parking.keywords')}>
            <div className={containerFluid}>
                <StaticImage layout={"fullWidth"} src='../images/garage.jpg' alt='Garage'/>
                <div className={inner}>
                    <div className={parkingBox}>
                        <FontAwesomeIcon icon={faVideo}/> <Trans>pages.parking.info2</Trans>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
export default ParkingPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
